
import { Link, useNavigate } from 'react-router-dom'
import { useStore } from '../store/Store';
import { motion, Variants } from "framer-motion";
import { IoBookmark, IoLogOut, IoNewspaperOutline, IoSettingsSharp } from 'react-icons/io5';
import SearchBox from './SearchBox';
import avatar from '../images/icons/random_avatar_man.jpg'
import logo from '../images/logo.png'
import { useTranslation } from 'react-i18next';
import i18n from '../locale/i18n';
import cookie from 'react-cookies'
import { RiArrowLeftSLine } from "react-icons/ri";
const itemVariants: Variants = {
  open: {
    opacity: 1,
    y: 0,
    transition: { type: "spring", stiffness: 300, damping: 24 }
  },
  closed: { opacity: 0, y: 20, transition: { duration: 0.2 } }
};
export default function Navbar() {
  const {isOpen, setisOpen, user, setuser}=useStore()
  const {search, setsearch, back_link}=useStore()
  const navigate=useNavigate()
  const {t}=useTranslation()

  const logout=()=>{
    window.localStorage.removeItem("access_token_elon")
    setuser(null)
    navigate('/')
  }

  const changeLang=(til:string)=>{
    i18n.changeLanguage(til)
    cookie.save('tilElon', til, { path: '/' })
  }
  return (
    <div className='big_navbar'>
        <Link to="/" className='brand'>
         <img alt="..." src={logo}/>
        </Link>
        <Link to={back_link} className='back_icon'>
        <RiArrowLeftSLine />
        </Link>
        <div className='navbar_search'>
        {/* <SearchBox search={search} setsearch={setsearch}/> */}
        </div>
        <div className='navbar_box'>
            {user===null?
            <div className='login_big_box_btn'>
            <div className='til_box'>
          <span onClick={()=>changeLang('uz')} className={`til_span ${t("lang")==='uz'?"active_lang":""}`}>UZ</span>
          <span onClick={()=>changeLang('ru')} className={`til_span ${t("lang")==='ru'?"active_lang":""}`}>RU</span>
          </div>
          <Link to={"/login"} className='login_icon icon_nav'>
           <button className='save_btn' >{t("login")}</button> 
          </Link></div>: <div className='navbar_dropdown'>
            <motion.nav
      initial={false}
      animate={isOpen ? "open" : "closed"}
      className="menu"
    >
      <motion.button
        whileTap={{ scale: 0.97 }}
        onClick={() => {setisOpen(!isOpen)}}
      >
       <div className='login_icon icon_nav'>
       <div className='avatar' style={{backgroundImage:`url(${user!==null && user.image!==null?user.image:avatar})`}} />
              <div className='user_text icon_nav'>
                <h3 className='icon_nav'>{user!=null?user.first_name:''}</h3>
                <p className='icon_nav'>{user!=null?user.phone:''}</p>
              </div>
            </div>
      </motion.button>
      <motion.ul 
      id={isOpen?"open_ul":'closed_ul'}
        variants={{
          open: {
            display:'flex',
            clipPath: "inset(0% 0% 0% 0% round 10px)",
            transition: {
              type: "spring",
              bounce: 0,
              duration: 0.7,
              delayChildren: 0.3,
              staggerChildren: 0.05
            }
          },
          closed: {
            display:'none',
            clipPath: "inset(10% 50% 90% 50% round 10px)",
            transition: {
              type: "spring",
              bounce: 0,
              duration: 0.3
            }
          }
        }}
        style={{ pointerEvents: isOpen ? "auto" : "none" }}
      >
        <motion.li variants={itemVariants}><Link className='drop_link' to={"/client"}><span><IoSettingsSharp /></span><p>{t("parametr")}</p></Link></motion.li>
        <motion.li variants={itemVariants}><Link className='drop_link' to={"/client/home/home"}><span><IoNewspaperOutline /></span><p>{t("my_adds")}</p></Link></motion.li>
        {/* <motion.li variants={itemVariants}><span><IoBookmark /></span><p>{t("saved")}</p></motion.li> */}
        {/* <motion.li variants={itemVariants}><span><FaMoneyBillWave /></span><p>{t("payments")}</p></motion.li> */}
        <motion.li onClick={logout} variants={itemVariants}><span><IoLogOut /></span><p>{t("exit")}</p></motion.li>
        <motion.li className='til_li' variants={itemVariants}><div className='til_box'>
          <span onClick={()=>changeLang('uz')} className={`til_span ${t("lang")==='uz'?"active_lang":""}`}>UZ</span>
          <span onClick={()=>changeLang('ru')} className={`til_span ${t("lang")==='ru'?"active_lang":""}`}>RU</span>
          </div></motion.li>
       
        
      </motion.ul>
    </motion.nav>
        </div>
          }
            
        </div>
    </div>
  )
}
