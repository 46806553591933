'use client';
import React, { useEffect, useState } from 'react'
import EmblaCarousel from '../../components/carousel/EmblaCarousel';
import { EmblaOptionsType } from 'embla-carousel';
import { Map, Placemark, YMaps, ZoomControl } from 'react-yandex-maps';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ServiceObject, useStore } from '../../store/Store';
import { httpGetRequest } from '../../host/Host';
import { filterPrice, formatFacebook, formatInstagram, formatTelegram, formatwebsite, responsive } from '../../components/Alert';
import avatar from '../../images/icons/random_avatar_man.jpg'
import { FaTelegramPlane } from "react-icons/fa";
import { FaFacebookF, FaGlobe, FaInstagram } from 'react-icons/fa6';
import { TbArrowBigLeftFilled } from 'react-icons/tb';
import "react-multi-carousel/lib/styles.css";
import Carousel from 'react-multi-carousel';

import CardHomeServiceMini from '../../components/cards/main_cards/CardHomeServiceMini';
const OPTIONS: EmblaOptionsType = {}
export default function HomeServiceProduct() {
    const {t}=useTranslation()
    const {id}=useParams()
    const navigate=useNavigate()
    const [data, setdata]=useState<ServiceObject|null>(null)
    const [number, setnumber]=useState(0)
    const {setloader, money_type, old_link, setback_link}=useStore()
    const [as_add_data, setas_add_data]=useState<ServiceObject[]>([])
    const [user_add_data, setuser_add_data]=useState<ServiceObject[]>([])
    useEffect(()=>{
      setback_link(formatOldLink(old_link))
     getData()
    }, [id])
    const getData=async()=>{
        setloader(true)
        try{
            var res=await httpGetRequest(`/services/house/${id}/detail/`)
            setdata(res.data)
            getAsAdds(res.data)
            if(res.data.created_by!==null){
              getUserAdds(res.data.created_by.id)
            }
            setloader(false)
            setnumber(number+1)
        }catch(err){
            setloader(false)
            navigate('/products?big_category=1&category=2')
        }
          
    }
    
    const getAsAdds=async(a:ServiceObject)=>{
      var str=''
      if(a.service_types!==null){
        a.service_types.forEach((item, key)=>{
          if(key!==0){
            str+=", "
          }
          str+=item.id
        })
      }
      var config={
        service_type__in:str.length!==0?str:null, 
        region__in:a.region!==null?String(a.region.id):null, 
        page:1,
        status:1
      }
      try{
        var res=await httpGetRequest('/services/house/', config)
        setas_add_data(res.data.results)
        setnumber(number+1)
        }catch(err){
        console.log(err)
        }
}
const getUserAdds=async(user_id:number)=>{
 try{
    var res=await httpGetRequest('/services/house/'+user_id, {status:1, page:1})
    setuser_add_data(res.data.results)
    setnumber(number+1)
    }catch(err){
    console.log(err)
    }
}

    const formatOldLink = (link:string) => {
      if (link.length === 0) {
        return "/products?big_category=1&category=2";
      } else {
        return "/products" + link;
      }
    };
  return (
    <div className='box'>
        {data!==null?
        <>
        <div  className='product_img_box'>
        <div  className='product_img_box_box'>
         <div className='product_img'>
         <EmblaCarousel images={data.images} options={OPTIONS} />
        
         </div>
         </div>
         {data.video!==null?<div className="yandex_map_box video_full_box">
            <div className='yandex_box'>
          <video controls src={data.video}></video>
        </div>
         </div>:<></>}
         </div>
         <div className='product_text'>
         <div className='product_text_head'>
          {data.created_by!==null?<div className='user_box'>
                <div className='avatar' style={{backgroundImage:`url(${data.created_by!==null && data.created_by.image!==null?data.created_by.image:avatar})`}}  />
                <p>{data.created_by.first_name}</p>
            </div>:<></>}
            <div className='add_contact_box'>
            <Link className='add_contact_icon' to={formatOldLink(old_link)}><TbArrowBigLeftFilled /></Link>
            {data.telegram!==null && data.telegram.length!==0?<a target='_blank' rel='noreferrer' className='add_contact_icon' href={formatTelegram(data.telegram)}><FaTelegramPlane /></a>:<></>}
            {data.instagram!==null && data.instagram.length!==0?<a target='_blank' rel='noreferrer' className='add_contact_icon' href={formatInstagram(data.instagram)}><FaInstagram /></a>:<></>}
            {data.facebook!==null && data.facebook.length!==0?<a target='_blank' rel='noreferrer' className='add_contact_icon' href={formatFacebook(data.facebook)}><FaFacebookF /></a>:<></>}
            {data.website!==null && data.website.length!==0?<a target='_blank' rel='noreferrer' className='add_contact_icon' href={formatwebsite(data.website)}><FaGlobe /></a>:<></>}
            
            {data.phone!==null && data.phone.length!==0?<a href={`tel: ${data.phone}`}>{data.phone}</a>:<></>}
            </div>
         </div>
            <div className='product_text_head'>
                <div className='head_text_title'>
            <h1>{data.name}</h1>
            <p className='product_price'>{money_type===2?filterPrice(data.price_usd)+" $":filterPrice(data.price_uzs)+t(t(" so'm"))}</p>
            </div>
            </div>
            <div className='product_box'>
            {data.region!=null || data.district!=null?<div className='product_item'>
            <span>{t("address")} : {data.region!=null?data.region[`name_${t("lang")==='ru'?'ru':'uz'}`]:''}, {data.district!=null?data.district[`name_${t("lang")==='ru'?'ru':'uz'}`]:''}</span>
            </div>:<></>}
            {data.fullname!=null?<div className='product_item'>
            <span>{t("fio")} : {data.fullname}</span>
            </div>:<></>}
            {data.gender!=null?<div className='product_item'>
            <span>{t("gender")} : {data.gender==='1'?t("male"):t("female")}</span>
            </div>:<></>}
            {data.payment_type!=null?<div className='product_item'>
            <span>{t("payment_type")} : {data.payment_type[`name_${t("lang")==='ru'?'ru':'uz'}`]}</span>
            </div>:<></>}
            {data.experience!=null?<div className='product_item'>
            <span>{t("experience")} : {data.experience} {t("year")}</span>
            </div>:<></>}
            {data.service_types!=null && data.service_types.length!==0?<div className='product_item'>
            <span>{t("service_type")} : {data.service_types.map((item, key)=>{
                var str=""
                if(key!==0){
                    str=", "
                }
                str+=item[`name_${t("lang")==='ru'?'ru':'uz'}`]
                return(str)
            })}</span>
            </div>:<></>}
            
            {data.additional!==null && data.additional.length!==0?<div style={{width:'100%', padding:'0'}} className='product_comment'>
               <p dangerouslySetInnerHTML={{__html:data.additional}}/> 
            </div>:''}
            </div>
            
         </div>
       
         
            {as_add_data.length>1?<div className='add_car_box'>
       <h2 className='as_add_title'>{t("as_adds")}</h2>
       <Carousel 
       swipeable={true}
       draggable={false}
       showDots={true}
       responsive={responsive}
       ssr={true} 
       infinite={true}
       autoPlay={false}
       autoPlaySpeed={1000}
       keyBoardControl={true}
       customTransition="all .5"
       transitionDuration={500}
       containerClass="carousel-container"
       removeArrowOnDeviceType={["tablet", "mobile"]}
       dotListClass="custom-dot-list-style"
       itemClass="carousel-item-padding-40-px"
       >
 {as_add_data.map((item, key)=>{
 if(item.id!==Number(id)){
  return(<div className='as_add_car_card'>
  <CardHomeServiceMini  data={item} key={key} number_key={key}/>
      </div>)
  
}
 })}
 
 
  
</Carousel>
       </div>:<></>}

       {user_add_data.length>1?<div className='add_car_box'>
       <h2 className='as_add_title'>{t("user_adds")}</h2>
       <Carousel 
       swipeable={true}
       draggable={false}
       showDots={true}
       responsive={responsive}
       ssr={true} 
       infinite={true}
       autoPlay={false}
       autoPlaySpeed={1000}
       keyBoardControl={true}
       customTransition="all .5"
       transitionDuration={500}
       containerClass="carousel-container"
       removeArrowOnDeviceType={["tablet", "mobile"]}
       dotListClass="custom-dot-list-style"
       itemClass="carousel-item-padding-40-px"
       >
 {user_add_data.map((item, key)=>{
  if(item.id!==Number(id)){
    return(<div className='as_add_car_card'>
    <CardHomeServiceMini  data={item} key={key} number_key={key}/>
        </div>)
    
  }
})}
 
</Carousel>
       </div>:<></>}
            
            
            </>
            :<></>}
    </div>
  )
}
