import React, { useState } from 'react'
import SimpleInputText from '../../components/SimpleInputText'
import GoogleSignInButton from '../../components/GoogleSignInButton'
import { Link, useNavigate } from 'react-router-dom'
import { dangerAlert, isValidEmail, successAlert } from '../../components/Alert'
import axios from 'axios'
import { api } from '../../host/Host'
import { useStore } from '../../store/Store'
import { useTranslation } from 'react-i18next'

export default function ChangePassword() {
  const [required, setrequired]=useState(false)
  const [send_code, setsend_code]=useState(false)
  const [email, setemail]=useState('')
  const [time, settime]=useState(300)
  const [otp, setotp]=useState('')
    const [password, setpassword]=useState('')
    const [confirm_password, setconfirm_password]=useState('')
    const {setuser, setloader}=useStore()
    const navigate=useNavigate()
    const {t} =useTranslation()


    const sendRegister=()=>{
      if(password.length!==0 && confirm_password.length!==0 && email.length!==0){
        if(!isValidEmail(email)){
          dangerAlert (t("email_error"))
        }else{
          if(password!==confirm_password){
            dangerAlert(t("parword_not_equal"))
           }else{
            var config={email}
            
            axios.post(`${api}/forgot-password/`, config).then(res=>{
               
               setsend_code(true)
               startTime()
            }).catch((err)=>{
              if(err.response.data.detail==='email_used'){
                dangerAlert (t("email_used"))
              }else{
                dangerAlert(t("has_error_system"))
              }
              
            })
           }
        }
       
      }else{
        setrequired(true)
      }
     }

     const startTime=()=>{
      var a=setInterval(()=>{
          var g=document.querySelector(".time_email")
          if(g?.innerHTML){
             var t=Number(g.innerHTML)
             if(t<1){
              clearInterval(a)
             }else{
              settime(t-1)
             }
          }else{
            clearInterval(a)
          }
      }, 1000)
     }

     const sendCode=()=>{
      if(password.length!==0 && confirm_password.length!==0 && email.length!==0 && otp.length!==0){
        if(!isValidEmail(email)){
          dangerAlert (t("email_error"))
        }else{
          if(password!==confirm_password){
            dangerAlert(t("parword_not_equal"))
           }else{
            var config={email, confirm_password, password, otp}
            setloader(true)
            axios.post(`${api}/reset-password/`, config).then(res=>{
               
               setloader(false)
               successAlert("Parol o'zgartirildi")
              navigate("/login")
            }).catch((err)=>{
              if(err.response.data.detail==='email_used'){
                dangerAlert (t("email_used"))
              }else{
                dangerAlert(t("has_error_system"))
              }
              
            })
           }
        }
       
      }else{
        setrequired(true)
      }
     }
  return (
    <div className='box login_box_big'>
        
        <div className='login_box'>
            <h1>{t("edit_password")}</h1>
            {send_code?<>
              <div className='login_item code_input'>
            <SimpleInputText required={required} placeholder={""} label={t("code_email")} value={otp} setvalue={setotp}/>
            <div className={`email_time ${time<=10?"red_time":""}`}>
      <span>{parseInt(String(time/60))<10?"0":""}{parseInt(String(time/60))}</span>
      <span>:</span>
      <span>{time%60<10?"0":""}{time%60}</span>

    </div>
            </div>
            </>:<>
            <div className='login_item'>
            <SimpleInputText required={required} placeholder={""} label={t("email")+" / "+t("phone")} value={email} setvalue={setemail}/>
            </div>
            <div className='login_item'>
            <SimpleInputText type='password' required={required} placeholder={""} label={t("new_password")} value={password} setvalue={setpassword}/>
            </div>
            <div className='login_item'>
            <SimpleInputText type='password' required={required} placeholder={""} label={t("check_password")} value={confirm_password} setvalue={setconfirm_password}/>
            </div>
            </>}
           
            {/* <div className='login_check'>
               <input className='check_input'  onChange={()=>{}} type="checkbox"/>
               <span>Foydalanish shartlarini qabul qiling</span>
            </div> */}
           
            <div className='filter_btns'>
                {/* <GoogleSignInButton/> */}
                <Link to="/login" className='restore_btn'>{t("login")}</Link> 
              {
                send_code?<button onClick={sendCode} className='save_btn'>{t("accept_password")}</button>:
                <button onClick={sendRegister} className='save_btn'>{t("edit_password")}</button> 
              }
               
               
            </div>
            </div>
       <p style={{display:'none'}} className='time_email'>{time}</p>
    </div>
  )
}
