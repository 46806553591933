import React, { useState } from 'react'
import SimpleInputText from '../../components/SimpleInputText'
import GoogleSignInButton from '../../components/GoogleSignInButton'
import { Link, useNavigate } from 'react-router-dom'
import { dangerAlert, isValidEmail, successAlert } from '../../components/Alert'
import axios from 'axios'
import { api } from '../../host/Host'
import { useStore } from '../../store/Store'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet-async'

export default function Register() {
  const [required, setrequired]=useState(false)
  const [email, setemail]=useState('')
    const [password, setpassword]=useState('')
    const [confirm_password, setconfirm_password]=useState('')
    const {setuser, setloader}=useStore()
    const navigate=useNavigate()
    const {t}=useTranslation()
   const sendRegister=()=>{
    if(password.length!==0 && confirm_password.length!==0 && email.length!==0){
      if(!isValidEmail(email)){
        dangerAlert (t("email_error"))
      }else{
        if(password!==confirm_password){
          dangerAlert(t("parword_not_equal"))
         }else{
          var config={email, password, confirm_password}
          setloader(true)
          axios.post(`${api}/register/`, config).then(res=>{
             
             successAlert("Ma'lumotlar saqlandi")
             setloader(false)
             login()
          }).catch((err)=>{
            if(err.response.data.detail==='email_used'){
              dangerAlert (t("email_used"))
              navigate("/login")
            }else{
              dangerAlert(t("has_error_system"))
            }
            setloader(false)
          })
         }
      }
     
    }else{
      setrequired(true)
    }
   }
   const login=()=>{
    setrequired(true)
    
    if(email.length!==0 && password.length!==0){
    setloader(true)
      axios.post(`${api}/login/`, {email, password}).then(res=>{
        window.localStorage.setItem("access_token_elon", res.data.access)
        setuser(res.data)
        setloader(false)
        navigate('/client')
      }).catch((err)=>{
        console.log(err)
        setloader(false)
        dangerAlert(t("erro_login_pass"))
      })
    }
 }
  return (
    <div className='box login_box_big'>
        <Helmet>
        <title>Toifa</title>
        <meta name="description" content="ARO, Alfa Romeo, Audi, Arcfox, Avatr, BAW, BMW, BYD, Barkas, Bentley, Bugatti, Buick, BAIC, Cadillac, Chana, Changan, Chery, Chevrolet, Chrysler, Citroen, Dadi, Daewoo, Dodge, Dong Feng, Eagle, Enovate, FAW, Fiat, Force, Ford, Foton, Geely, Genesis, Great Wall, GAC, GAC Trumpchi, Hafei, Haval, Hawtai, Honda, Hyundai, Hongqi, Infiniti, Isuzu, JAC, JMC, Jaguar, Jeep, Jin Bei, Jetour, Kia, Lamborghini, Lancia, Land Rover, Lexus, Lifan, Lincoln, Leap Motor, Link Tour, LI, MG, Mazda, Mercedes-Benz, Mercedes-Maybach, Mini, Mitsubishi, NIO, Nissan, Neta, Opel, Porsche, Ravon, Renault, Rover, Saab, Santana, Shuanghuan, Skoda, Smart, Tesla, Toyota, Volkswagen, Volvo, Zeekr, VAZ (Lada), GAZ, Moskvich, RAF, Retro-avtomobillar, UAZ, LiXiang, HiPhi, Jetta, Hycan" />
      </Helmet>
        <div className='login_box'>
            <h1>{t("register")}</h1>
            <div className='login_item'>
            <SimpleInputText required={required} placeholder={""} label={t("email")+" / "+t("phone")} value={email} setvalue={setemail}/>
            </div>
            <div className='login_item'>
            <SimpleInputText type='password' required={required} placeholder={""} label={t("password")} value={password} setvalue={setpassword}/>
            </div>
            <div className='login_item'>
            <SimpleInputText type='password' required={required} placeholder={""} label={t("check_password")} value={confirm_password} setvalue={setconfirm_password}/>
            </div>
            {/* <div className='login_check'>
               <input className='check_input'  onChange={()=>{}} type="checkbox"/>
               <span>Foydalanish shartlarini qabul qiling</span>
            </div> */}
           
            <div className='filter_btns'>
                {/* <GoogleSignInButton/> */}
                <Link to="/login" className='restore_btn'>{t("login")}</Link> 
               <button onClick={sendRegister} className='save_btn'>{t("register")}</button> 
               
            </div>
            </div>
       
    </div>
  )
}
