'use client';

import React, { useEffect } from 'react';

import house from '../../images/icons/house1.png'
import car from '../../images/icons/car.png'
import { motion } from "framer-motion"
import { Link, useLocation } from 'react-router-dom';
import ImageBox from '../../components/ImageBox';
import { useTranslation } from 'react-i18next';
import { useStore } from '../../store/Store';



const Main: React.FC = () => {
 const {t}=useTranslation()
 const {setback_link}=useStore()
 useEffect(()=>{
    setback_link("/")
 }, [])
return (
    <div   className='big_box main_box_mig'>

    <motion.div initial={{y:100, opacity:0}}  animate={{ y: 0, opacity:1 }}
     transition={{ ease: "easeOut", duration: 0.5 }}>
      <Link to="/categories?big_category=1" className='big_cart'>
      <ImageBox  alt='image' src={house} />
        <div className='big_cart_text'>
        {t("houses")}
        </div>
      </Link>
      <Link  to="/categories?big_category=2" className='big_cart'>
      <ImageBox alt='image' src={car} />
        <div className='big_cart_text'>
       {t("cars")}
        </div>
      </Link>
      </motion.div>
  </div>
  );
};

export default Main;
